import React, { useRef } from 'react'
import Box from '@mui/system/Box';
import useTheme from '@mui/material/styles/useTheme';
import { useInView } from 'framer-motion';
import Typewriter from 'typewriter-effect';
import { getHomeAnimatingTextsForTypewriter } from '../pagesContents/home/getHomeAnimatingTextsForTypewriter';


const sxStyleWrap = {
    '& .Typewriter': {
        perspective: "1000px",
        display: 'flex',
        padding: "50px",
        '& .Typewriter__wrapper': {
            bgcolor: "primary2.main",
            color: "primary2.contrastText",
            display: "block",
            maxWidth: "fit-content",
            textAlign: "center",
            mx: "auto",
            my: "15px",
            p: "5px",
            borderRadius: "5px",
            whiteSpace: "pre",
            fontSize: [ "smaller", "small", "medium" ],
            transition: "all 0.5s",
            "&:hover": {
                transform: "rotateY(-50deg)",
            }
        },
        '& .Typewriter__cursor': {
            color: "secondary.contrastText",
        },
        '& span': {

        }
    },
}

const TypewritingAbout = () => {

    const refWrap = useRef(null)
    const isInView = useInView(refWrap)
    const theme = useTheme()
    return (
        <Box
            className="clay"
            ref={refWrap}
            sx={sxStyleWrap}
        >
            {isInView &&
                < Typewriter
                    onInit={(typewriter) => {
                        getHomeAnimatingTextsForTypewriter(typewriter, theme.palette.primary.contrastText, theme.palette.primary.main);
                    }} />
            }
        </Box>
    )
}

export default TypewritingAbout